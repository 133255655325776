import React from 'react';
import {AmmoForm, Report} from "../types";
import {useFormik} from "formik";
import {Autocomplete, Button, IconButton, styled, TextField} from "@mui/material";
import {addMinutes, format, parse} from "date-fns";
import {
  ErrorMessages, initialAmmoValue,
  defaultInitialTargetValue,
  mgrsCoordinatesRegExp,
  objectiveOptions, Objectives,
  resultOptions, Results
} from "../const";
import {removeAtIndex} from "../utils/arrayUtils";
import InputMask from "react-input-mask";
import TargetInput from "./TargetInput";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import * as yup from "yup";
import AmmoInput from "./AmmoInput";
import chechHasAmmo from "../utils/chechHasAmmo";
import checkHasAmmo from "../utils/chechHasAmmo";

const hhmmddMMyyyyRegExp = new RegExp(/^(?:[01]\d|2[0-3]):[0-5]\d, (?:0[1-9]|[12]\d|3[01])\.(?:0[1-9]|1[0-2])\.\d{4}$/);

const editReportSchema = yup.object().shape({
  startTime: yup.string().required(ErrorMessages.Required).matches(hhmmddMMyyyyRegExp, { message: ErrorMessages.HHmmddMMyyyy}),
  objective: yup.string().required(ErrorMessages.Required),
  targets: yup.array().of(yup.object({
    value: yup.string().required(ErrorMessages.Required).matches(mgrsCoordinatesRegExp, { message: 'Ціль має бути у форматі MGRS' }),
    description: yup.string(),
  })),
  ammos: yup.array().of(yup.object({
    ammoType: yup.string(),
    ammoQuantity: yup.string(),
    detonator: yup.string(),
    detonatorQuantity: yup.string(),
    initiator: yup.string(),
    initiatorQuantity: yup.string(),
  })),
  results: yup.array().of(yup.object({
    result: yup.string().required(ErrorMessages.Required),
    description: yup.string(),
  })),
});

type EditReportFormValuesType = Pick<Report, 'objective' | 'targets' | 'results'> & {
  startTime: string;
  ammos: AmmoForm[];
};

interface ReportEditFormProps {
  report: Report;
  onSubmit: (report: Report) => void;
}
const ReportEditForm: React.FC<ReportEditFormProps> = ({ report, onSubmit }) => {
  const {
    handleSubmit,
    values,
    errors,
    touched,
    setFieldValue,
    handleChange,
  } = useFormik<EditReportFormValuesType>({
    initialValues: {
      startTime: format(report.startTime, 'HH:mm, dd.MM.yyyy'),
      objective: report.objective,
      targets: report.targets,
      ammos: report.ammos.map(ammo => ({
        ...ammo,
        ammoQuantity: String(ammo.ammoQuantity),
        detonatorQuantity: String(ammo.detonatorQuantity),
        initiatorQuantity: String(ammo.initiatorQuantity),
      })),
      results: report.results,
    },
    validationSchema: editReportSchema,
    onSubmit: (values) => {
      let startDate;
      try{
        startDate = parse(values.startTime, 'HH:mm, dd.MM.yyyy', new Date());
      }catch (e) {
      }

      onSubmit({
        ...report,
        ...values,
        ammos: values.ammos.map((ammo) => ({
          ...ammo,
          ammoQuantity: parseInt(ammo.ammoQuantity) || undefined,
          detonatorQuantity: parseInt(ammo.detonatorQuantity) || undefined,
          initiatorQuantity: parseInt(ammo.initiatorQuantity) || undefined,
        })),
        startTime: startDate || new Date(),
        endTime: addMinutes(startDate || new Date(), 20),
      });
    }
  });

  const isAmmoFieldsDisabled = !chechHasAmmo(values.objective);

  const addTarget = () => {
    setFieldValue('targets', [...values.targets, { value: defaultInitialTargetValue, description: ''}]);
  }

  const removeTarget = (index: number) => {
    setFieldValue('targets', removeAtIndex(values.targets, index));
  }

  const addResult = () => {
    setFieldValue('results', [...values.results, {result: values.objective === Objectives.Hit ? Results.Hit : Results.Executed, description: ''}]);
  }

  const removeResult = (index: number) => {
    setFieldValue('results', removeAtIndex(values.results, index));
  }

  const addAmmo = () => {
    setFieldValue('ammos', [...values.ammos, initialAmmoValue]);
  }

  const removeAmmo = (index: number) => {
    setFieldValue('ammos', removeAtIndex(values.ammos, index))
  }

  const handleChangeObjective = (value: string) => {
    //@ts-ignore
    if(!checkHasAmmo(value)){
      setFieldValue('ammo', []);
    }else{
      setFieldValue('ammo', [initialAmmoValue])
    }
    setFieldValue('objective', value);
  };

  return <StyledForm onSubmit={handleSubmit}>
    <InputMask
      mask="99:99, 99.99.9999"
      value={values.startTime}
      onChange={handleChange}
    >
      {/*@ts-ignore*/}
      {(inputProps) => <StyledTextField
        {...inputProps}
        autoComplete="off"
        label="Час"
        name="startTime"
        error={touched.startTime && Boolean(errors.startTime)}
        helperText={touched.startTime && errors.startTime}
      />}
    </InputMask>
    <Autocomplete
      disableClearable
      value={values.objective}
        onChange={(e, value) => handleChangeObjective(value)}
      options={objectiveOptions}
      renderInput={(params) => <StyledTextField
        {...params}
        name="objective"
        label="Мета"
        error={touched.objective && Boolean(errors.objective)}
        helperText={touched.objective && errors.objective}
      />}
    />
    {values.targets.map((target, i) => <TargetInput
      key={i}
      target={target.value}
      targetDescription={target.description || ''}
      setFieldValue={setFieldValue}
      index={i}
      isLast={values.targets.length === i + 1}
      addTarget={addTarget}
      removeTarget={() => removeTarget(i)}
      //@ts-ignore
      error={errors.targets?.[i]?.value}
      touched={touched.targets?.[i]?.value}
    />)}
    {!isAmmoFieldsDisabled && values.ammos.map((ammo, i) => <AmmoInput
      key={i}
      index={i}
      isLast={values.ammos.length === i + 1}
      addAmmo={addAmmo}
      removeAmmo={() => removeAmmo(i)}
      ammo={ammo}
      setFieldValue={(key: string, value: any) => setFieldValue(`ammos[${i}.${key}`, value)}
      handleChange={handleChange}
      touched={errors?.ammos?.[i]}
      errors={errors.ammos?.[i]}
    />)}
    {values.results.map((result, i) => <ResultWrapper key={i}>
      <ResultFieldWrapper>
        <ResultAutocomplete
          disableClearable
          value={result.result}
          onChange={(e, value) => setFieldValue(`results[${i}].result`, value)}
          options={resultOptions}
          renderInput={(params) => <TextField
            {...params}
            name={`result[${i}].result`}
            label={`Результат ${i+1}`}
            //@ts-ignore
            error={touched.results && Boolean(errors.results?.[i]?.result)}
            //@ts-ignore
            helperText={touched.results && errors.results?.[i]?.result}
          />}
        />
        {i !== 0 ? <IconButton
          size="large"
          color="inherit"
          onClick={() => removeResult(i)}
        >
          <RemoveIcon />
        </IconButton> : <BtnPlaceholder />}
        {i === values.results.length - 1 ? <IconButton
          size="large"
          color="inherit"
          onClick={addResult}
        >
          <AddIcon />
        </IconButton> : <BtnPlaceholder />}
      </ResultFieldWrapper>
      <StyledTextField
        name={`results[${i}].description`}
        value={result.description}
        onChange={handleChange}
        label={"Опис результату"}
      />
    </ResultWrapper>)}
    <Button variant="contained" type="submit">Зберегти</Button>
  </StyledForm>
};

export default ReportEditForm;

const StyledForm = styled('form')`
  display: flex;
  flex-direction: column;
`;
const StyledTextField = styled(TextField)`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing(4)};
`;
const ResultWrapper = styled('div')`
  display: flex;
  flex-direction: column;
`;
const ResultFieldWrapper = styled('div')`
  display: flex;
  margin-bottom: ${({ theme }) => theme.spacing(4)};
`;
const BtnPlaceholder = styled('div')`
  width: 48px;
  height: 48px;
  min-width: 48px;
`;
const ResultAutocomplete = styled(Autocomplete)`
  flex: 1;
  margin-right: ${({ theme }) => theme.spacing(4)};
`;