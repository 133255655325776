import React, {useContext, useEffect, useRef, useState} from 'react';
import {Autocomplete, IconButton, styled, TextField} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import InputMask from 'react-input-mask';
import {mgrsCoordinatesRegExp} from "../const";
import {FormikErrors} from "formik";
import {Target} from "../types";
import AppContext from "../AppContext";
import {useSnackbar} from "notistack";
import SnackbarCloseButton from "./SnackbarCloseButton";
import SnackbarConfirmButton from "./SnackbarConfirmButton";

interface TargetInputProps {
  target: string;
  targetDescription: string;
  setFieldValue: any;
  error?: string | FormikErrors<Target>;
  touched?: boolean;
  index: number;
  isLast: boolean;
  addTarget: () => void;
  removeTarget: () => void;
}

const TargetInput: React.FC<TargetInputProps> = ({
  target,
  targetDescription,
  setFieldValue,
  error,
  touched,
  index,
  isLast,
  addTarget,
  removeTarget,
                                                 }) => {

  const descriptionRef = useRef<HTMLInputElement>(null);
  const isFirst = index === 0;

  const { targetMap } = useContext(AppContext);
  const {enqueueSnackbar} = useSnackbar();
  // const [options, setOptions] = useState<any>([]);
  //
  // useEffect(() => {
  //   setOptions(Object.entries(targetMap).map(([key, value]) => ({
  //     label: `${key}: ${value}`,
  //     value: key,
  //     target: value,
  //   })))
  // }, [targetMap]);

  useEffect(() => {
    if(targetMap[targetDescription] && target !== targetMap[targetDescription]){
      console.log('has match');
      enqueueSnackbar(`Використати координати збереженоі цілі (${targetMap[targetDescription]})?`, {
        autoHideDuration: null,
        action: snackbarKey => <><SnackbarConfirmButton snackbarKey={snackbarKey} handleApprove={() => {
          setFieldValue(`targets[${index}].value`, targetMap[targetDescription]);
        }}/><SnackbarCloseButton snackbarKey={snackbarKey}/></>,
        preventDuplicate: true,
        anchorOrigin: { horizontal: 'left', vertical: 'top'}
      });
    }
  }, [targetDescription]);

  return <Wrapper>
    <TargetWrapper>
      <InputMask
        mask="99a aa 99999 99999"
        value={target}
        onChange={(e) => {
          const val = e.target.value?.toUpperCase();
          setFieldValue(`targets[${index}].value`, val);
          if(val?.match(mgrsCoordinatesRegExp)) {
            descriptionRef?.current?.focus();
          }
        }
        }
      >
        {/*@ts-ignore*/}
        {(inputProps) => <TargetTextField
          {...inputProps}
          autoComplete='off'
          name={`targets[${index}].value`}
          label={`Ціль${isFirst && isLast ? '' : ' '+(index+1)}`}
          error={touched && Boolean(error)}
          helperText={touched && error}
        />}
      </InputMask>
      {!isFirst ? <IconButton
        size="large"
        color="inherit"
        onClick={removeTarget}
      >
        <RemoveIcon />
      </IconButton> : <BtnPlaceholder />}
      {isLast ? <IconButton
        size="large"
        color="inherit"
        onClick={addTarget}
      >
        <AddIcon />
      </IconButton> : <BtnPlaceholder />}
    </TargetWrapper>
    {/*<Autocomplete renderInput={props => <DescriptionTextField*/}
    {/*  {...props}*/}
    {/*  autoComplete='off'*/}
    {/*  label="Опис цілі"*/}
    {/*  name={`targets[${index}].description`}*/}
    {/*  value={targetDescription}*/}
    {/*  onChange={(e) => setFieldValue(`targets[${index}].description`, e.target.value)}*/}
    {/*  inputRef={descriptionRef}*/}
    {/*/>} options={options} />*/}
    <DescriptionTextField
      autoComplete='off'
      label="Опис цілі"
      name={`targets[${index}].description`}
      value={targetDescription}
      onChange={(e) => setFieldValue(`targets[${index}].description`, e.target.value)}
      inputRef={descriptionRef}
    />
  </Wrapper>
};

export default TargetInput;

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-bottom: ${({theme}) => theme.spacing(4)};
`;
const TargetWrapper = styled('div')`
  display: flex;
  margin-bottom: ${({theme}) => theme.spacing(4)};
`;
const TargetTextField = styled(TextField)`
  flex: 1;
  margin-right: ${({ theme }) => theme.spacing(4)};
`;
const BtnPlaceholder = styled('div')`
  width: 48px;
  height: 48px;
  min-width: 48px;
`;
const DescriptionTextField = styled(TextField)`
  flex: 1;
`;