import React from "react";
import {useSnackbar, SnackbarKey} from "notistack";
import {IconButton} from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';

const SnackbarConfirmButton: React.FC<{ snackbarKey: SnackbarKey, handleApprove: () => void }> = ({ snackbarKey, handleApprove }) => {
  const { closeSnackbar } = useSnackbar();

  return <IconButton
    aria-label="approve"
    color="inherit"
    sx={{ p: 0.5 }}
    onClick={() => {
      handleApprove();
      closeSnackbar(snackbarKey)
    }}
  >
    <CheckIcon />
  </IconButton>;
};

export default SnackbarConfirmButton;