import {Config} from "../../types";
import {defaultInitialTargetValue} from "../../const";

export const defaultCrewValue = 'CC';
export const crewOptions = ['Філін', defaultCrewValue];

export type CrewCompositionItem = string | { value: string, label: string};

type CrewCompositionOptions = {
  [x: string]: CrewCompositionItem[]
}
export const crewCompositionOptions: CrewCompositionOptions = {
  'Філін': ['Філін', 'Дайчі', 'Джо', 'Шерп'],
  [defaultCrewValue]: ['CC', 'Атом', 'Кузен', 'Ронін', 'Каміль', 'Навєс', 'Хантер','Нован', 'Саймон'],
};

export const configInitialValues: Config = {
  crew: defaultCrewValue,
  crewComposition: [],
  vehicleType: 'Vampire',
  takeOffLocation: 'Мала Токмачка',
  attackArea: 'околиці Малої Токмачки',
  frequencies: '2.4 Vampire',
  altitude: '80-150м',
  areaOfResponsibility: '118 ОМБр',
  initialTargetValue: defaultInitialTargetValue,
}